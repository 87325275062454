import React from "react"
import QRCode from "qrcode.react"

import Layout from "../components/layout"

import "react-day-picker/lib/style.css"

import { activities } from "../utils/activitiy_types"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { getQueryString } from "../utils/getQueryString"

import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import dateFnsFormat from "date-fns/format"
import dateFnsParse from "date-fns/parse"
import { DateUtils } from "react-day-picker"
import { Link } from "gatsby"
import SEO from "../components/seo"

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale })
}

const convertToMinutes = (hours: number, minutes: string) => {
  return hours * 60 + parseInt(minutes)
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { url: null }
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
    const minutes = convertToMinutes(
      event.target.elements.formDurationHours.value,
      event.target.elements.formDurationMinutes.value
    )
    const minimalActivity = {
      date: event.target.elements.formDate.value,
      minutes: minutes,
      topic: event.target.elements.formTopic.value,
    }
    if (event.target.elements.formActivityType.value != "--") {
      minimalActivity.activity_type =
        event.target.elements.formActivityType.value
    }
    if (event.target.elements.formEndDate.value) {
      minimalActivity.end_date = event.target.elements.formEndDate.value
    }
    if (event.target.elements.formNote.value) {
      minimalActivity.note = event.target.elements.formNote.value
    }
    if (event.target.elements.formOtherActivityType.value) {
      minimalActivity.other_activity_type =
        event.target.elements.formOtherActivityType.value
    }
    const url = `onecpd://${getQueryString(minimalActivity)}`
    this.setState({ url: url })
  }

  render() {
    const format = "yyyy-MM-dd"
    return (
      <Layout>
        <SEO title="Generate QR Code" />
        <p>
          Use this form to generate and download QR codes to prepopulate
          activities in{" "}
          <a href="https://cpd.rcvs.org.uk/">RCVS's 1CPD platform</a>. Or, if
          you would prefer to generate them programmatically{" "}
          <Link to="/documentation">please see our instructions</Link>. All
          fields are optional.
        </p>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="formTopic">
            <Form.Label>Topic</Form.Label>
            <Form.Control maxLength={80} />
          </Form.Group>

          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <br />
            <DayPickerInput
              inputProps={{ className: "form-control", id: "formDate" }}
              formatDate={formatDate}
              format={format}
              parseDate={parseDate}
              placeholder={``}
            />
          </Form.Group>

          <Form.Group controlId="formEndDate">
            <Form.Label>End date</Form.Label>
            <br />
            <DayPickerInput
              inputProps={{ className: "form-control", id: "formEndDate" }}
              formatDate={formatDate}
              format={format}
              parseDate={parseDate}
              placeholder={``}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group controlId="formDurationHours">
                <Form.Label>Hours</Form.Label>
                <Form.Control as="select">
                  {[...Array(999).keys()].map(hour => (
                    <option key={hour}>{hour}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formDurationMinutes">
                <Form.Label>Minutes</Form.Label>
                <Form.Control as="select">
                  {["00", "15", "30", "45"].map(minutes => (
                    <option key={minutes}>{minutes}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="formActivityType">
            <Form.Label>Activity type</Form.Label>
            <Form.Control as="select">
              {activities.map(activity => (
                <option key={activity}>{activity}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formOtherActivityType">
            <Form.Label>Please specify if other</Form.Label>
            <Form.Control maxLength={80} />
          </Form.Group>

          <Form.Group controlId="formNote">
            <Form.Label>Note</Form.Label>
            <Form.Control as="textarea" aria-label="Note" maxLength={4000} />
          </Form.Group>

          <Button variant="primary" type="submit">
            Make code
          </Button>
        </Form>
        {this.state.url && (
          <div className="HpQrcode">
            <QRCode
              value={this.state.url}
              size={256}
              ref={ref => (this.qrcode = ref)}
            />
            <br />
            <Button onClick={() => this.qrcode.download("1CPD.png")}>
              Download
            </Button>
          </div>
        )}
      </Layout>
    )
  }
}

export default IndexPage
