export const activities = [
  "--",
  "Case discussion",
  "Clinical audit",
  "Clinical skills lab",
  "Conference",
  "Course",
  "Distance learning - formal",
  "Distance learning - informal",
  "Distance learning - webinar",
  "External qualification",
  "In-house training",
  "Other - formal",
  "Other - workplace",
  "Other - self-directed",
  "Peer feedback",
  "Preparing a new lecture",
  "Preparing a paper",
  "Research",
  "Seminar",
  "Significant event analysis",
  "Veterinary reading",
  "Work based observation",
  "Workshop",
]
